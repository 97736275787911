import img1 from "./1.png";
import img2 from "./2.png";
import img3 from "./3.png";
import img4 from "./4.png";
import img5 from "./5.png";
import img6 from "./6.png";
import img7 from "./7.png";
import img8 from "./8.png";
import img9 from "./9.png";
import img10 from "./10.png";
import img11 from "./11.png";
import img12 from "./12.png";
import img13 from "./13.png";
import img14 from "./14.png";
import img15 from "./15.png";

type YouTubeVideoThumbnail = {
    source: string,
    link: string
}

const yImages: YouTubeVideoThumbnail[] = [
    {source: img1, link: "https://youtu.be/3a2QLCUbw28?si=5YfYvpPQEvL2b4EG"},
    {source: img2, link: "https://youtu.be/7iYX7Tp4cM4?si=Z1miy032Y0yKHkXz"},
    {source: img3, link: "https://youtu.be/FeebCBbUVJg?si=71kk-Fe6vz5Y-n_0"},
    {source: img4, link: "https://youtu.be/4xeMTwwuqYM?si=PKGmGzB0gBO7IKKZ"},
    {source: img5, link: "https://youtu.be/F4OneFa09VQ?si=6tyjPA2IoQtonP_b"},
    {source: img6, link: "https://youtu.be/sCDrfFMM2Ak?si=QxOwDEkGqNboeOtg"},
    {source: img7, link: "https://youtu.be/4as8nhC1ojA?si=8PInyksCWt1SrGan"},
    {source: img8, link: "https://youtu.be/GkCsx85wBkE?si=X91HWMwf7QioNvcn"},
    {source: img9, link: "https://youtu.be/iem0BRTpK1k?si=IUHSV1d79jR-qR-T"},
    {source: img10, link: "https://youtu.be/OP2rXCdNDQ8?si=qBQUtIE9eRDXg1H1"},
    {source: img11, link: "https://youtu.be/SbWMRgWjkFk?si=3pEvBpWIcVuuuMcv"},
    {source: img12, link: "https://youtu.be/Gsc2AT-jsWg?si=JKxf5iZW71nL5dGJ"},
    {source: img13, link: "https://youtu.be/Z6hk-hBV3UE?si=9PqfFmDeP30JEOht"},
    {source: img14, link: "https://youtu.be/5FpcptYIT3o?si=9UKZVpknojgoSuyF"},
    {source: img15, link: "https://youtu.be/cPeKbtTsbwE?si=Pyqw7nQgkOjahqLD"}
];

export default yImages;