import img0 from "./Prayer.png";
import img1 from "./Quote.png";
import img2 from "./Quran 22.png";
import img3 from "./Quran 833.png";
import img4 from "./Quran 1519.png";


export const iImages = [
    img0,
    img1,
    img2,
    img3,
    img4,
]